import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
const DantooSentryUtils = window.DantooSentryUtils;
if (DantooSentryUtils)
    Sentry.init(DantooSentryUtils.combineConfigs({
        dsn: PUBLIC_SENTRY_DSN,
        allowUrls: [/^https:\/\/totalcsgo\.com/],
        tracesSampleRate: 0.01,
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 0.01,
    }));
const handleErrorAfterSentry = ({ error, event }) => {
    console.log(error);
    return {
        message: 'Whoops!',
        code: error?.code ?? 'UNKNOWN',
    };
};
export const handleError = handleErrorWithSentry(handleErrorAfterSentry);
